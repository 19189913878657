import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AiFillLock } from "react-icons/ai";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { green } from "@mui/material/colors";

const SelectCategory = (props) => {
  const [quizzes, setQuizzes] = useState({});
  const [error, setError] = useState(null);
  const [collapsedSections, setCollapsedSections] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      const appToken = sessionStorage.getItem("webtoken");
      const username = sessionStorage.getItem("username");
      const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetCategories?username=${username}`;
      const auth = "Bearer " + appToken;

      try {
        const response = await axios.post(
          URL,
          {},
          { headers: { Authorization: auth } }
        );
        const transformedData = transformData(response.data);
        setQuizzes(transformedData);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          await updateToken();
          fetchQuizzes();
        } else {
          setError(error.message);
        }
      }
    };

    fetchQuizzes();
  }, []);

  const updateToken = async () => {
    // Define your logic to update the token here
  };

  const needSubscribe = () => {
    Swal.fire({
      text:
        "Click Subscribe to go to the cart. \nClick Cancel to return to the current screen.",
      showCancelButton: true,
      confirmButtonText: "Subscribe",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/wordpacks");
      }
    });
  };

  const toggleSection = (collectionName) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [collectionName]: !prevState[collectionName],
    }));
  };

  const transformData = (quizzes) => {
    return quizzes.reduce((acc, quiz) => {
      const { CollectionName } = quiz;
      if (!acc[CollectionName]) {
        acc[CollectionName] = [];
      }
      acc[CollectionName].push(quiz);
      return acc;
    }, {});
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!Object.keys(quizzes).length) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ borderRadius: "5px", flex: "1", overflowY: "auto" }}>
        <div style={{ fontSize: "1em", color: "white" }}>
          Select Quiz Category
        </div>
        <div style={{ maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}>
          {Object.keys(quizzes).map((collectionName) => (
            <div key={collectionName}>
              <div
                style={{
                  fontSize: "1em",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#4CAF50", // Green background
                  border: "2px solid #388E3C", // Darker green border
                  borderRadius: "5px", // Rounded corners
                  textShadow: `
                      1px 1px 1px rgba(0, 0, 0, 0.1),
                      2px 2px 1px rgba(0, 0, 0, 0.2),
                      3px 3px 1px rgba(0, 0, 0, 0.3)
                    `, // Multiple shadows for 3D effect
                  transition: "background-color 0.3s ease", // Smooth transition for background color
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundColor = "#45a049")
                } // Change background color on hover
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundColor = "#4CAF50")
                } // Revert background color on mouse leave
                onClick={() => toggleSection(collectionName)}
              >
                {collectionName}{" "}
                {collapsedSections[collectionName] ? (
                  <AiOutlineMinus />
                ) : (
                  <AiOutlinePlus />
                )}
              </div>
              <ul
                style={{
                  listStyleType: "none",
                  paddingLeft: "0px",
                  display: collapsedSections[collectionName] ? "block" : "none",
                  transition: "max-height 0.3s ease",
                }}
              >
                {quizzes[collectionName].map((item, index) => (
                  <li
                    style={{
                      fontSize: "1em",
                      fontWeight: "normal",
                      cursor: "pointer",
                      color: "white",
                      padding: "2px", // Padding for the text
                      borderRadius: "5px", // Rounded corners for the background
                      
                      transition: "background-color 0.3s ease", // Smooth transition for background color
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "#48D1CC"; // Change background color on hover
                      e.currentTarget.style.color = "black"; // Change text color on hover
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = ""; // Revert background color on mouse leave
                      e.currentTarget.style.color = "white"; // Revert text color on mouse leave
                    }}
                    key={index}
                    onClick={() =>
                      item.IsLocked
                        ? needSubscribe()
                        : props.setCategory(item.Id)
                    }
                  >
                    {item.CategoryName} {item.IsLocked && <AiFillLock />}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectCategory;
