import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';

const EventRegistration = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    emailAddress: '',
    mobileNumber: '',
    receiveSMS: false,
    acceptTerms: false
  });
  const location = useLocation();
  const { id } = useParams();



  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoad, setIsLoad] = useState(0);

  const validateMobileNumber = (number) => isValidPhoneNumber(number);

  const handleChangeMnumber = (value) => {
    const newFormData = {
      ...formData,
      mobileNumber: value
    };
    setIsLoad(isLoad + 1);
    setFormData(newFormData);
  };

  useEffect(() => {
    localStorage.setItem('eId', id);
  }, [])

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    const newFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    };
    setIsLoad(isLoad + 1);
    setFormData(newFormData);

    if (name === 'emailAddress') {
      const errors = {};
      if (!/\S+@\S+\.\S+/.test(value)) {
        errors.emailAddress = 'Invalid email';
      } else {
        let ret = await checkEmailidExists(value);
        errors.emailAddress = ret;
      }
      setFormErrors(errors);
      setIsLoad(isLoad + 1);
    }
  };

  const checkEmailidExists = async (emailid) => {
    let returnValue = '';
    try {
      const response = await fetch(`https://s3napi.s3nsoftware.com/api/UserMasters/UserEmailExisted?Emailid=${emailid}`);
      const data = await response.json();

      if (data === 1) {
        returnValue = 'Email already registered';
      } else {
        returnValue = 'Email available';
      }
    } catch (error) {
      returnValue = "Error checking Email";
    }
    return returnValue;
  };

  useEffect(() => {
    const errors = validate(formData);
    if (Object.keys(errors).length === 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [isLoad]);

  const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    if (!/\S+@\S+\.\S+/.test(values.emailAddress)) {
      errors.emailAddress = 'Email is invalid';
    }
    let retn = checkEmailidExists(values.emailAddress);
    if (retn === 'Email already registered') {
      errors.emailAddress = retn;
    }
    if (!values.acceptTerms) {
      errors.acceptTerms = 'You must agree to the terms and conditions';
    }
    return errors;
  };

  const generateRandomCode = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const createOrder = (data, actions) => {
    const disc = `${formData.emailAddress}#*#${formData.password}#*#${formData.mobileNumber}#*#${formData.receiveSMS}`;

    return actions.order.create({
      purchase_units: [{
        amount: {
          currency_code: 'USD',
          value: "1.00"
        },
        custom_id: "YourCustomDataHere",
        description: "Description",
      }],
      application_context: {
        brand_name: "Your Brand",
        shipping_preference: "NO_SHIPPING"
        // return_url: `${window.location.origin}/payment-success`,
        // cancel_url: `${window.location.origin}/payment-cancel`
      }
    });
  };

  const onPaymentSuccess = (data, actions) => {
    return actions.order.capture().then(details => {
      const paymentInfo = {
        payerID: data.payerID,
        orderID: data.orderID,
        paymentID: details.id,
        paymentToken: details.purchase_units[0].payments.captures[0].id,
        amount: details.purchase_units[0].amount.value,
        currency: details.purchase_units[0].amount.currency_code,
        description: details.purchase_units[0].description,
        phoneNumber: details.payer.phone.phone_number.national_number,
        email: details.payer.email_address, // Extracting the payer's email address
        firstname: details.payer.name.given_name,
        lastname: details.payer.name.surname
      };


      handleSubmit(paymentInfo);
    }).catch(error => {
      console.error('Error capturing order:', error);
    });
  };

  const handleSubmit = async (paymentInfo) => {


    const formData = {
      emailAddress: document.querySelector('input[name="emailAddress"]').value,
      password: document.querySelector('input[name="password"]').value,
      mobileNumber: document.querySelector('input[name="mobileNumber"]').value,
    };

    //console.log("form ", JSON.stringify(formData))


    const userMaster = {
      UserName: formData.emailAddress,
      UserPassword: formData.password,
      UserRoles: 'eUser',
      UserEmailId: formData.emailAddress,
      Verified: true,
      Verifiedon: new Date().toISOString(),
      ActivationCode: generateRandomCode(20),
      IsNSF: false
    };

    //console.log("usermaster ", JSON.stringify(userMaster))

    //let iid= localStorage.setItem('eId',id);

    const signupModel = {
      MobileNumber: formData.mobileNumber,
      IsCheckSMS: true,
      UserMaster: userMaster,
      PaymentInfo: paymentInfo,
      EventId: id
    };


    //console.log("sign ", JSON.stringify(signupModel))

    const response = await fetch('https://s3napi.s3nsoftware.com/api/UserMasters/SaveEUserInformation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(signupModel)
    });

    if (response.ok) {
      Swal.fire({
        title: 'Registration completed!',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
      setFormData({
        password: '',
        confirmPassword: '',
        emailAddress: '',
        mobileNumber: '',
        receiveSMS: false,
        acceptTerms: false
      });
    } else {
      Swal.fire({
        title: 'Registration failed!',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  };

  const [price, setPrice] = useState('0.00');
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://s3napi.s3nsoftware.com/api/QuizDatas/GetEvents',
        headers: {}
      };

      axios.request(config)
        .then((response) => {
          setCards(response.data);
        })
        .catch((error) => {
          //console.log(error);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const card = cards.find(card => card.Id === parseInt(id));
    const amount = card ? card.Amount : '0';
    setPrice(amount);
  }, [cards, id]);

  return (
    <>
  
  <div className="card card-container col-md-6" >
    <ul>
      <li>Students will require an spelling account to access class quizzes. Please register or sign up now.</li>
      <li>If you have an spelling account Please login and continue</li>
    </ul>
    
      <img hidden
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        alt="profile-img"
        className="profile-img-card"
      />
      <div style={styles.form}>
        <input type="text" name="emailAddress" value={formData.emailAddress} onChange={handleChange} placeholder="Email Address" />
        {formErrors.emailAddress && <p style={styles.errorText}>{formErrors.emailAddress}</p>}
        <br />
        <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" />
        {formErrors.password && <p style={styles.errorText}>{formErrors.password}</p>}
        <br />
        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="Confirm Password" />
        {formErrors.confirmPassword && <p style={styles.errorText}>{formErrors.confirmPassword}</p>}
        <br />
        <PhoneInput
          international
          defaultCountry="US"
          value={formData.mobileNumber}
          name="mobileNumber"
          placeholder="Mobile Number"
          onChange={handleChangeMnumber}
        />
        {formErrors.mobileNumber && <p style={styles.errorText}>{formErrors.mobileNumber}</p>}
        <br />
        <label>
          <input type="checkbox" name="receiveSMS" checked={formData.receiveSMS} onChange={handleChange} />
          <span> Receive SMS notifications</span>
        </label>
        <br />
        <label>
          <input type="checkbox" name="acceptTerms" checked={formData.acceptTerms} onChange={handleChange} />
          <span> I agree to the Terms and Conditions</span>
        </label>
        {formErrors.acceptTerms && <p style={styles.errorText}>{formErrors.acceptTerms}</p>}
        <br />
        <PayPalScriptProvider options={{
          "client-id": "AbjTGxNRgw-BNbD2ILmTePbIWjsxHJs951dfZAOh73eJNK-BwkCpbNN_0AllDm7Fjo4GE_cqwjivh0st",
          "components": "buttons",
          "currency": "USD"
        }}>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onPaymentSuccess}
            disabled={!isFormValid}
          />
        </PayPalScriptProvider>
      </div>
    </div>  </>
   
  );
};

export default EventRegistration;

// Styles
const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width:'500px',
    height: '100vh',
  },
  form: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    color: 'red',
    fontSize: '0.8rem',
  },
  submitButton: {
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
  }
};
