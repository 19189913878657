import { createContext, useContext, useState } from "react";

const QuizContext=createContext();

export const QuizProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
  
    const addToCart = (item) => {
      setCartItems([...cartItems, item]);
    };
  
    const removeFromCart = (item) => {
      setCartItems(cartItems.filter((apple) => apple !== item));
    };
  
    return (
      <QuizContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
        {children}
      </QuizContext.Provider>
    );
  };
  
  export const useQuiz = () => {
    return useContext(QuizContext);
  };