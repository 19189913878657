// src/components/Carousel.js
import React from 'react';
import CarouselItem from './CarouselItem';

const Carousel = ({ items }) => (
    <div className="carousel slide" data-bs-ride="carousel" id="carousel-1" >
        <div className="carousel-inner h-100">
            {items.map((item, index) => (
                <CarouselItem key={index} {...item} />
            ))}
        </div>
        <div>
            {/* <a className="carousel-control-prev" href="#carousel-1" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span><span className="visually-hidden">Previous</span>
            </a> */}
            {/* <a className="carousel-control-next" href="#carousel-1" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span><span className="visually-hidden">Next</span>
            </a> */}
        </div>
        <div className="carousel-indicators">
            {/* {items.map((_, idx) => (
                <button key={idx} type="button" data-bs-target="#carousel-1" data-bs-slide-to={idx} className={idx === 0 ? "active" : ""}></button>
            ))} */}
        </div>
    </div>
);

export default Carousel;
