import React, { useState } from 'react';
import './Player.css'; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faArrowLeft, faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Player = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="playcontainer">
      <div className="top-nav1">
        <button className="nav-button prev-button">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <span className="question-count">QUESTION 06/30</span>
        <button className="nav-button next-button">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <div className="play-area ">
        <button className="play-button" id="playPauseButton" onClick={togglePlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </button>
      </div>
      <div className="input-area">
        <input type="text" placeholder="Enter the Spelling" />
        <button className="go-button">GO</button>
      </div>
      <div className="hints">
        <button className="hint-button"><FontAwesomeIcon icon={faInfoCircle} /> PART OF SPEECH</button>
        <button className="hint-button"><FontAwesomeIcon icon={faInfoCircle} /> DEFINITION</button>
        <button className="hint-button"><FontAwesomeIcon icon={faInfoCircle} /> ETYMOLOGY</button>
        <button className="hint-button"><FontAwesomeIcon icon={faInfoCircle} /> SENTENCE</button>
        <button className="show-all"><FontAwesomeIcon icon={faInfoCircle} /> SHOW ALL</button>
        <button className="play-all"><FontAwesomeIcon icon={faInfoCircle} /> PLAY ALL</button>
      </div>
    </div>
  );
};

export default Player;
