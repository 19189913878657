import React, { useState } from 'react'
import styles from './WordModel.module.css'

const WordModel = (props) => {

    const closeModal = () => {
        props.updateIsOpen(false);
    };
    return (
        <div className={styles.modal_container}>
            {props.open && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <span className={styles.close} onClick={closeModal}>&times;</span>
                        <div>

                            <label style={{textDecoration:'underline',fontWeight:'bold',fontSize:'25'}}>{props.currentquestion.QuizType==1?props.currentquestion.Word:props.currentquestion.Question}</label>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{fontWeight:'bold'}}>{"Part of speech: "}</td>
                                        <td style={{textAlign:'start',alignContent:'start'}}>{props.currentquestion.pos}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:'bold'}}>{"Definition: "}</td>
                                        <td style={{textAlign:'start',alignContent:'start'}}>{props.currentquestion.defn}</td>
                                    </tr>
                                    <tr>
                                        <td style={{fontWeight:'bold'}}>{"Etymology: "}</td>
                                        <td style={{textAlign:'start',alignContent:'start'}}>{props.currentquestion.etym}</td>
                                    </tr>

                                    <tr>
                                        <td style={{fontWeight:'bold'}}>{"Sentence: "}</td>
                                        <td style={{textAlign:'start'}} >{props.currentquestion.sent}</td>
                                    </tr>

                                </tbody>
                            </table>
                            {/* <div style={{
          display: 'grid',
          gridGap: '1px',
        }}>
        
          <div style={{ ...gridItemStyle, gridRow: '1', gridColumn: '1' }}>{"Part of speech:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '1', gridColumn: '2' }}>{props.currentquestion.pos}</div>
          <div style={{ ...gridItemStyle, gridRow: '2', gridColumn: '1' }}>{"Definition:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '2', gridColumn: '2' }}>{props.currentquestion.defn}</div>
          <div style={{ ...gridItemStyle, gridRow: '3', gridColumn: '1' }}>{"Etymology:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '3', gridColumn: '2' }}>{props.currentquestion.etym}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '1' }}>{"Sentence:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '2' }}>{props.currentquestion.sent}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '1 / 3' }}></div>
        
        </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WordModel