import React, { useEffect, useState } from "react";
import styles from "./PlayQuiz.module.css";
import SelectCategory from "./SelectCategory";
import SelectQuiz from "./SelectQuiz";
import PlayArea from "./PlayArea";
import Score from "./Score";
import QuizzesModel from "../../common/QuizzesModel";
import { QuizProvider } from "../../context/QuizContext";
import FlyingAnimationComponent from "./FlyingAnimationComponent";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams, useLocation } from "react-router-dom";

const PlayQuiz = () => {
  const { id } = useParams();
  const location = useLocation();

  const [cat, setCat] = useState(0);
  const [subcat, setSubCat] = useState(0);
  const [type, setType] = useState(0);
  const [quizId, setQuizId] = useState(0);
  const [openQuizModel, setOpenQuizModel] = useState(false);
  const [corrects, setCorrects] = useState(0);
  const [wrongs, setWrongs] = useState(0);

  const [tanswers, settAnswers] = useState([]);

  const [answers, setAnswers] = useState("");

  const [dictionary, setDictionary] = useState({});

  const [questions, setQuestions] = useState([]);
  const [currentquestion, setCurrentQuestion] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  const [cheers, setCheers] = useState(true);

  const [audiolink, setAudioLink] = useState("");
  const [quiztype, setQuizType] = useState(0);

  const updateAnswers = (a) => {
    setAnswers(a);
  };

  const updateQuizType = (q) => {
    setQuizType(q);
  };

  const updateCheers = (c) => {
    setCheers(c);
  };

  const updateAudioLink = (a) => {
    setAudioLink(a);
  };
  const updateCurrentQuestion = (q) => {
    setCurrentQuestion(q);
  };

  const updateCurrentIndex = (i) => {
    setCurrentIndex(i);
  };

  const setCategory = (cat) => {
    setCat(cat);
    setSubCat(0);
  };
  const setSubCategory = (subid) => {
    setSubCat(subid);
  };
  const setSubType = (type) => {
    setType(type);
  };

  const setOpenQuizzesModel = (status) => {
    setOpenQuizModel(status);
  };

  const setQuizzId = (qid) => {
    setQuizId(qid);
  };

  const updateCorrect = (c) => {
    //////console.log("corrects ",corrects)
    setCorrects(c);
  };
  const updateWrongs = (w) => {
    ////console.log("wrongs ",wrongs)
    setWrongs(w);
  };

  const updatetAnswers = (a) => {
    ////console.log("wrongs ",wrongs)
    settAnswers(a);
  };

  const updateDictionary = (d) => {
    setDictionary(d);
  };
  const updateQuestions = (q) => {
    setQuestions(q);
  };

  const getLastAttempted = async (username) => {
    try {
      const response = await axios.post(
        `https://s3napi.s3nsoftware.com/api/UserMaster/GetLastAttempted`,
        null, // No request body needed
        {
          headers: {
            Accept: "application/json",
          },
          params: {
            username: username,
          },
        }
      );
      if (response.status == 200) {
        setQuizId(response.data);
      }
      //console.log(response.data); // Handle the response data
    } catch (error) {
      console.error("Error fetching last attempted quiz:", error);
    }
  };
  const appToken = sessionStorage.getItem("webtoken");
  const username = sessionStorage.getItem("username");
  const auth = "Bearer " + appToken;

  useEffect(() => {
    const quizId = id ? parseInt(id, 10) : 0;

    //console.log('Quiz ID:', quizId);
    //console.log('Current Path:', location.pathname);

    if (location.pathname == "/playquiz") {
    } else if (location.pathname == "/") {
    } else if (location.pathname == "/home") {
    }

    setQuizId(quizId);
    if (quizId == 0) getLastAttempted(username);
  }, [id]);

  const handleSaveQuiz = async (ans, dict, qs) => {
    //console.log("submit", "called");
    //console.log("answers", answers);
    //console.log("dictionary", dictionary);

    //console.log("answers", ans);
    //console.log("dictionary", dict);

    const quiz = {
      UserId: username,
      QuizId: quizId,
      QuizAnswers: ans,
      QuizDate: new Date().toISOString(),
      NoOfAttempts: 1,
      FirstAttemptOn: new Date().toISOString(),
      LastAttemptOn: new Date().toISOString(),
      QuizStatus: qs,
      IncAns: "",
    };

    if (dict && Object.keys(dict).length > 0) {
      quiz.IncAns = Object.entries(dict)
        .map(([key, value]) => `${key},${value}`)
        .join(";");
    }

    await saveUserQuiz(quiz);

    // // Replace with your API endpoint and appropriate method to save the quiz
    // const response = await fetch('https://s3napi.s3nsoftware.com/api/saveQuiz', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(quiz)
    // });

    // const result = await response.json();
    // return result;
  };

  const getFormattedDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-indexed
    const day = now.getDate().toString().padStart(2, "0");

    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const saveUserQuiz = async (userQuiz) => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://s3napi.s3nsoftware.com/api/UserQuizs/SaveUserQuiz",
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.toString(),
        },
        data: JSON.stringify(userQuiz),
      };
      axios
        .request(config)
        .then(async (response) => {
          const result = response.data;
          let res = parseInt(result, 10);

          if (res > 0 && userQuiz.QuizStatus) {
            updateCheers(true);

            const result = await ShowOpenNextQuiz(username);

            if (result && result.length > 0) {
              const splitted = result.split("##");
              Swal.fire({
                title: splitted[0], // Title from the first split value
                text: splitted[3],  // Text from the fourth split value
                icon: "warning",
                showCancelButton: true,  // Show the Cancel button
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                allowOutsideClick: false, // Disable closing the modal by clicking outside
                allowEscapeKey: false,    // Disable closing the modal by pressing the Esc key
                allowEnterKey: false,     // Disable the Enter key from triggering the confirm button
                preConfirm: () => {
                  return new Promise((resolve) => {
                    resolve('ok'); // Resolve with a value indicating the OK button was clicked
                  });
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  setQuizId(splitted[2])
                  // OK button was clicked
                  console.log('OK button clicked');
                } else if (result.isDismissed) {
                  // Cancel button was clicked or modal was dismissed
                  console.log('Cancel button clicked or modal dismissed');
                  setQuizId(0)
                  setQuizType(0);

                  setSubCategory(0);

                }
              });
              
            }else{
              Swal.fire({
                title: "Quiz Completed!",
                text: result,
                icon: "warning",
                confirmButtonText: "Ok",
              });

            }

           
          }
          ////console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          //console.log(error);
          return 0;
        });

      // if (response.ok) {
      //   const result = await response.text();
      //   return parseInt(result, 10);
      // } else {
      //   // Handle HTTP error response
      //   console.error('Failed to save quiz:', response.status);
      //   return 0;
      // }
    } catch (error) {
      // Handle network or other errors
      console.error("Error during save quiz:", error);
      return 0;
    }
  };

  const ShowOpenNextQuiz = async (username) => {
    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/QuizDatas/ShowOpenNextQuiz",
        null, // No data to be sent in the body
        {
          params: {
            Id: quizId,
            firstFind: true,
            username: username,
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      return response.data.toString();
    } catch (error) {
      console.error("There was an error making the request:", error);
    }
  };

  useEffect(() => {
    const OpenNextQuiz = async (catid, username) => {
      try {
        const responce = await axios.post(
          "https://s3napi.s3nsoftware.com/api/QuizDatas/OpenDefaultQuiz",
          null,
          {
            params: {
              catId: catid,
              username: username ? username : "null",
            },
            headers: {
              Accept: "application/json",
            },
          }
        );
        if (responce.status == 200) {
          setQuizId(responce.data);
        }
      } catch (error) {}
    };
    if (cat > 0 && subcat == 0) OpenNextQuiz(cat, username);
  }, [cat,subcat]);

  return (
    <QuizProvider>
      <div className={styles.quiz_container}>
        <div className={styles.sidebar}>
          <div className={styles.sidebar_container}>
            <div className={styles.category_section}>
              <SelectCategory setCategory={setCategory} />
            </div>

            <div className={styles.subcategory_section}>
              <SelectQuiz
                catId={cat}
                setOpenQuizzesModel={setOpenQuizzesModel}
                setSubCategory={setSubCategory}
                setSubType={setSubType}
                setQuizId={setQuizId}
              />
            </div>
          </div>
        </div>

        <div className={styles.main_content}>
          <PlayArea
            quizId={quizId}
            updateWrongs={updateWrongs}
            updateCorrect={updateCorrect}
            corrects={corrects}
            wrongs={wrongs}
            updateDictionary={updateDictionary}
            answers={answers}
            updateAnswers={updateAnswers}
            updatetAnswers={updatetAnswers}
            tanswers={tanswers}
            dictionary={dictionary}
            questions={questions}
            updateQuestions={updateQuestions}
            updateCurrentIndex={updateCurrentIndex}
            currentIndex={currentIndex}
            updateCurrentQuestion={updateCurrentQuestion}
            currentquestion={currentquestion}
            updateCheers={updateCheers}
            cheers={cheers}
            updateAudioLink={updateAudioLink}
            audiolink={audiolink}
            updateQuizType={updateQuizType}
            quiztype={quiztype}
            handleSaveQuiz={handleSaveQuiz}
            setCategory={setCategory}
            setSubCategory={setSubCategory}
            setQuizzId={setQuizzId}
          />
        </div>

        <div className={styles.extra_content}>
          <Score
            corrects={corrects}
            wrongs={wrongs}
            tanswers={tanswers}
            answers={answers}
            updateAnswers={updateAnswers}
            dictionary={dictionary}
            questions={questions}
            handleSaveQuiz={handleSaveQuiz}
          />
        </div>

        {openQuizModel ? (
          <QuizzesModel
            open={openQuizModel}
            subid={subcat}
            type={type}
            setOpenQuizzesModel={setOpenQuizzesModel}
            setQuizzId={setQuizzId}
          />
        ) : null}
      </div>
    </QuizProvider>
  );
};

export default PlayQuiz;
