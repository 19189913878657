import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/navbar/CNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import PlayQuiz from "./components/quiz/PlayQuiz";
import FullWordModel from "./common/FullWordModel";
import MyQuizzes from "./components/myquizzes/MyQuizzes";
import Admin from "./components/admin/Admin";
import WordPacks from "./components/wordpacks/WordPacks";
import EventRegistration from "./components/signup/EventRegistration";
import UploadUserPhoto from "./components/UploadUserPhoto";
import EventRegistrations from "./components/EventRegistrations";
import SideNavbar from "./components/navbar/SideNavbar";
import { usePreference } from "./context/UserContext";
import LoginModel from "./components/login/LoginModel";
import SignUpModel from "./components/signup/SignUpModel";
import Player from "./Player";
import CommentModel from "./common/CommentModel";
import FeedbackForm from "./components/FeedbackForm";
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    isLoginOpen,
    updateIsLoginOpen,
    isSignUpOpen,
    updateIsSignUpOpen,
    searchedId,
    updateSearchedId,
  } = usePreference();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const updateIsOpen = (i) => {
    setIsOpen(i);
  };
  const [selectedid, setSelectedId] = useState(0);
  const updateSelectedId = (s) => {
    setSelectedId(s);
    // alert(s);
  };

  return (
    <>
      <div className={`App ${sidebarOpen ? "" : "sidebar-close"}`}>
        <SideNavbar
          sidebarOpen={sidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
        />
        <div className="homesection">
          <Navbar
            visible={true}
            updateSelectedId={updateSelectedId}
            handleSidebarToggle={handleSidebarToggle}
            updateIsOpen={updateIsOpen}
          />
          <div className="container2">
            <Routes>
              <Route path="/e" element={<Home />} />
              {/* <Route path='/' element={<Home />} /> */}
              <Route path="/player" element={<Player />} />
              {/* <Route path='/home' element={<Home />} /> */}
              <Route path="/home" element={<PlayQuiz />} />
              <Route path="/" element={<PlayQuiz />} />
              <Route path="/playquiz" element={<PlayQuiz />} />
              <Route path="/myquizzes" element={<MyQuizzes />} />
              <Route path="/playquiz/:id" element={<PlayQuiz />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/wordpacks" element={<WordPacks />} />
              <Route path="/events/:id" element={<EventRegistration />} />
              <Route path="/working" element={<UploadUserPhoto />} />
              <Route path="/event" element={<EventRegistrations />} />
              <Route path="/feedback" element={<FeedbackForm/>}/>
            </Routes>
          </div>
        </div>
      </div>

      <FullWordModel
        open={isOpen}
        updateIsOpen={setIsOpen}
        selectedid={selectedid}
      />
      <LoginModel open={isLoginOpen} updateIsOpen={updateIsLoginOpen} />
      <SignUpModel open={isSignUpOpen} updateIsOpen={updateIsSignUpOpen} />
      <CommentModel />
    </>
  );
}

export default App;
