import React, { useEffect, useState } from "react";
import axios from "axios";

import styles from "./SelectQuiz.module.css";
import QuizzesModel from "../../common/QuizzesModel";
import { Hidden } from "@mui/material";
import Swal from "sweetalert2";

const SelectQuiz = (props) => {
  // Use props.catid to access the catid prop
  //////console.log("SelectQuiz",props.catId);

  const [subcat, SetSubcat] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    const appToken = sessionStorage.getItem("webtoken");
    const username = sessionStorage.getItem("username");

    const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetSubCategories?catid=${props.catId}&username=${username}`;

    const auth = "Bearer " + appToken;

    const getAllQuizzesEx = async (iteration = 1) => {
      if (props.catId != 0) {
        try {
          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: URL.toString(),
            headers: {
              Authorization: auth.toString(),
            },
          };

          axios
            .request(config)
            .then((response) => {
              //////console.log("Select ", JSON.stringify(response.data));
              SetSubcat(response.data);
            })
            .catch((error) => {
              //////console.log(error);
              setError(error.message);
            });
        } catch (error) {
          if (
            error.response &&
            (error.response.status === 401 || error.response.status === 403) &&
            iteration === 1
          ) {
            await updateToken();
            await getAllQuizzesEx(2);
          } else {
            setError(error.message);
          }
        }
      }
    };

    getAllQuizzesEx();
  }, [props.catId]);

  const updateToken = async () => {
    // Define your logic to update the token here
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!subcat) {
    return <div>Loading...</div>;
  }

  const handleSelection = (type, subcat,number) => {
    //////console.log("Completed",type);
    //////console.log("Subcat",subcat);

    if(number==0){
      Swal.fire({title:'Subcategory',text:'No quizzes for select',timer:3000})
      return;
    }

    
    if(number==1){
      Swal.fire({title:'Subcategory',text:'1 quiz for select',timer:3000})
      loadFirstQuiz(subcat,type)

      props.setSubCategory(subcat);
     props.setSubType(type);
      

      return;
    }else{
      props.setSubCategory(subcat);
      props.setSubType(type);
      props.setOpenQuizzesModel(true);

    }
  };

  const loadFirstQuiz=(sub,type)=>{
    const appToken = sessionStorage.getItem('webtoken')
    const username = sessionStorage.getItem('username')

    const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetQuizzesBySubCatId?username=${username}&subId=${sub}&type=${type}`;

    const auth = "Bearer " + appToken;

    console.log(" "+URL)

    getAllQuizzesEx(1,URL,auth)

  }
  const getAllQuizzesEx = async (iteration = 1,URL,auth) => {

    try {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: URL.toString(),
            headers: {
                'Authorization': auth.toString()
            }
        };

        axios.request(config)
            .then((response) => {
                console.log("Model Quizzes  ", JSON.stringify(response.data));
                props.setQuizId(response.data.Subcats[0].Quizzes[0].Id);
            })
            .catch((error) => {
                //console.log(error);
                setError(error.message);
            });

    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403) && iteration === 1) {
            await updateToken();
            await getAllQuizzesEx(2,URL,auth);
        } else {
            setError(error.message);
        }
    }
};

  return (
    <div>
      <div className={styles.cardheader}>Select Quiz</div>
      <ul className={styles.listgroup}>
        {subcat.map((item, index) => (
          <li className={styles.listgroupitem} key={index}>
            <div className={styles.container}>
              <div className={styles.row}>
                <h6>
                  {item.SubCatName} (
                  {item.Completed + item.Inprogress + item.Notattempted})
                </h6>
              </div>
              <div className={styles.row}>
                <div
                  className={styles.circle}
                  onClick={() => {
                    handleSelection(1, item.Id,item.Completed);
                  }}
                >
                  <p>{item.Completed}</p>
                </div>
                <div
                  className={styles.circle}
                  onClick={() => {
                    handleSelection(2, item.Id,item.Inprogress);
                  }}
                >
                  <p>{item.Inprogress}</p>
                </div>
                <div
                  className={styles.circle}
                  onClick={() => {
                    handleSelection(3, item.Id,item.Notattempted);
                  }}
                >
                  <p>{item.Notattempted}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectQuiz;
