import React, { memo, useState } from "react";
import WordModel from "../../common/WordModel";
import styles from "./Score.module.css";

const Score = ({
  wrongs,
  corrects,
  tanswers,
  dictionary,
  questions,
  handleSaveQuiz,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentquestion, setCurrentQuestion] = useState({});

  const handleClick = (e) => {
    setIsOpen(true);
    let foundItem = questions.find((item) => item.Id == e.split("|")[1]);
    setCurrentQuestion(foundItem);
  };

  const updateIsOpen = (o) => {
    setIsOpen(o);
  };

  return (
    <>
      <div className={styles.parentDiv}>
        <div className={styles.childDiv} style={{ color: "green" }}>
          {corrects}
        </div>
        <div className={styles.childDiv} style={{ color: "red" }}>
          {wrongs}
        </div>
      </div>
      <div className={styles.lineContainer}>
        <span className={styles.score}>Score</span>
      </div>
      <div className={styles.dictionary}>
        {Object.entries(dictionary).map(([key, value]) => (
          <div key={key} className={styles.dictionaryItem}>
            {value.toLocaleLowerCase() ===
            key.split("|")[2].toLocaleLowerCase() ? (
              <span className={styles.correct}>{value}</span>
            ) : (
              <>
                <span className={styles.incorrect}>{value}:</span>
                <span
                  onClick={() => handleClick(key)}
                  className={styles.correct}
                >
                  ({key.split("|")[2]})
                </span>
              </>
            )}
          </div>
        ))}
      </div>
      <div className={styles.saveButtonContainer}>
        <button className={styles.saveButton} onClick={handleSaveQuiz}>
          Save Quiz
        </button>
      </div>
      <WordModel
        open={isOpen}
        updateIsOpen={updateIsOpen}
        currentquestion={currentquestion}
      />
    </>
  );
};

export default memo(Score);
