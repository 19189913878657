import React, { memo, useEffect, useState } from 'react'
import styles from './Questions.module.css';
import AudioPlayer from './AudioPlayer';
import { AiFillBackward, AiFillForward } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { usePreference } from '../../context/UserContext';
import axios from 'axios';
import PathDisplay from './PathDisplay';

const Questions = (props) => {

  const { comment, updateComment, isCommentOpen, updateCommetOpen, isLoginOpen, updateIsLoginOpen, isSignUpOpen, updateIsSignUpOpen } = usePreference();
  const username = sessionStorage.getItem('username');
  const[quizPath,setQuizPath]=useState("");

  // useEffect(() => {
  //   // Code to execute when quiztype changes
  //   //console.log(quiztype)
  // }, [quiztype]);
  ////console.log(" values "+props.currentquestion)

  const handleComments = () => {
    updateCommetOpen(true);
    //console.log(" values " + props.currentquestion.QuizId)
    //console.log(" values " + props.currentquestion.QuizId)
    //console.log(" cat " + props.currentquestion.CatId)
    //console.log(" subcat " + props.currentquestion.SubCat)
   

    const userComments = {
      Username: username,
      CatId: props.currentquestion.CatId,
      Subcat: props.currentquestion.SubCat,
      QuizId: props.quizId,
      Question: props.currentquestion.QuizInd,
      FirstInserted: new Date(),
      Comment: '',
      WordId: props.currentquestion.Id
    };

    updateComment(userComments)

  }

  useEffect(() => {

    const getCurrentQuizPath = async (username, quizid, subcat, catid) => {
        try {
            // Manually build the URL
            const url = `https://s3napi.s3nsoftware.com/api/UserMaster/CurrentQuizPath?username=${encodeURIComponent(username)}&quizid=${encodeURIComponent(quizid)}&subcat=${encodeURIComponent(subcat)}&catid=${encodeURIComponent(catid)}`;

            const response = await axios.post(url, {
                headers: {
                    'Accept': 'application/json',
                },
            });

            if (response.status === 200) {
                setQuizPath(response.data);
            }
            //console.log(response.data); // Handle the response data
        } catch (error) {
            console.error('Error fetching current quiz path:', error);
        }
    };

    if ( props.quizId && props.currentquestion.SubCat && props.currentquestion.CatId) {
        //console.log("values " + props.quizId);
        //console.log("cat " + props.currentquestion.CatId);
        //console.log("subcat " + props.currentquestion.SubCat);

        getCurrentQuizPath(username, props.quizId, props.currentquestion.SubCat, props.currentquestion.CatId);
    }

}, [username, props.quizId, props.currentquestion.SubCat, props.currentquestion.CatId]);


  return (
    <div className={styles.container}>
      <PathDisplay quizPath={quizPath} />
      <div className={styles.topRow}>
        <div className={styles.side} onClick={props.handlePrevClick}><AiFillBackward size={30} /></div>
        <div className={styles.pagination}><strong> Question:</strong> {props.currentIndex + 1} / {props.quiqsize} {props.quiqsize > 0 ? <Link onClick={handleComments}>Comment</Link> : ''} </div>
        <div className={styles.side} onClick={props.handleNextClick}><AiFillForward size={30} /></div>
      </div>
      <div className={styles.middle}>
        {props.quiztype === 1 && (
          <AudioPlayer audioUrl={props.audiolink} currentIndex={props.currentIndex} size={100} color={'#fff'} init={true} quiz={true} replay={props.replay}/>
        )}
        {props.quiztype === 3 && <h3>{props.currentquestion.Question}</h3>}
      </div>
    </div>


  )
}

export default memo(Questions)