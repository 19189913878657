import React from "react";

const mainDarkColors = [
  "#00008B", // DarkBlue
  "#8B0000", // DarkRed
  "#006400", // DarkGreen
  "#2F4F4F", // DarkSlateGray
  "#4B0082", // Indigo
  "#800000", // Maroon
  "#2C3E50", // Midnight Blue
  "#000080", // Navy
  "#483D8B", // DarkSlateBlue
  "#556B2F", // DarkOliveGreen
  "#191970", // Midnight Blue
  "#3B3B3B", // Dark Gray
  "#1B2631", // Very Dark Blue
  "#1C1C1C", // Very Dark Gray
  "#2C2C2C", // Very Dark Slate
  "#3D3D3D", // Charcoal
  "#4B5320", // Army Green
  "#2E8B57", // Sea Green
  "#4B0082", // Indigo
  "#3D2B1F", // Dark Brown
  "#2F4F4F", // Dark Slate Gray
  "#1B1B1B", // Jet Black
  "#343434", // Dark Charcoal
];

const getRandomMainColor = () => {
  return mainDarkColors[Math.floor(Math.random() * mainDarkColors.length)];
};
const truncateSegment = (segment) => {
  if (segment.length > 15) {
    return `${segment.slice(0, 15)}...`;
  }
  return segment;
};
const PathDisplay = ({ quizPath }) => {
  const pathSegments = quizPath.split("/");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontFamily: "Arial, sans-serif",
      }} 
    >
      {pathSegments.map((segment, index) => (
        <React.Fragment key={index}>
          <span
            style={{
                fontWeight: 'bold',
                fontSize: '16px',
                color: getRandomMainColor(),
                cursor: 'pointer',
                textDecoration: 'none',
                marginBottom:'10px'
            }}
            title={segment} // Shows the full value on hover
            onMouseEnter={(e) => e.currentTarget.style.textDecoration = 'underline'}
            onMouseLeave={(e) => e.currentTarget.style.textDecoration = 'none'}
      >
            {truncateSegment(segment)}
          </span>
          {index < pathSegments.length - 1 && (
            <span style={{ margin: "0 5px", color: "#777" }}>/</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PathDisplay;
