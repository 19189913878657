import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import { Button } from "reactstrap";

const formatDateTime = (dateString) => {
  return format(new Date(dateString), "hh:mm a"); // Format as 240709 01:00 PM
};

const EventRegistrations = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const username = sessionStorage.getItem("username");

    // Fetch data from the API
    fetch(
      `https://s3napi.s3nsoftware.com/api/UserMaster/GetEventRegistraions?username=${username}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" mt={5}>
          <Typography color="error">Error: {error.message}</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <div  >
      <Container>
        <Box display="flex" variant="h4" justifyContent="center" mt={5}>
          <Typography color="success">Event Registrations</Typography>
        </Box>

        <TableContainer  component={Paper} style={{height:'650px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>UserName</TableCell>
                <TableCell>Session Type</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.UserName || "N/A"}</TableCell>
                  <TableCell>{item.SessionType}</TableCell>
                  <TableCell>{formatDateTime(item.StartTime)}</TableCell>
                  <TableCell>{formatDateTime(item.EndTime)}</TableCell>
                  <TableCell>{item.Description}</TableCell>
                  <TableCell>{item.EmailId.trim()}</TableCell>
                  <TableCell>
                    {item.MobileNumber ? item.MobileNumber.trim() : "N/A"}
                  </TableCell>
                </TableRow>
              ))}{data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.UserName || "N/A"}</TableCell>
                  <TableCell>{item.SessionType}</TableCell>
                  <TableCell>{formatDateTime(item.StartTime)}</TableCell>
                  <TableCell>{formatDateTime(item.EndTime)}</TableCell>
                  <TableCell>{item.Description}</TableCell>
                  <TableCell>{item.EmailId.trim()}</TableCell>
                  <TableCell>
                    {item.MobileNumber ? item.MobileNumber.trim() : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button />
      </Container>
    </div>
  );
};

export default EventRegistrations;
