import React, { useEffect, useState } from 'react';
import styles from './InputWithButton.module.css';

function InputWithButton(props) {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const getSpecialCharacters = (inputString) => {
    // Use a regular expression to match special characters
    const specialCharacterRegex = /[^a-zA-Z0-9\s]/g;

    // Use the match() method to get an array of matched characters
    const specialCharactersArray = inputString.match(specialCharacterRegex);

    // Check if there are any special characters
    if (specialCharactersArray) {
      // Join the array into a string of special characters
      const specialCharactersString = specialCharactersArray.join('');
      return specialCharactersString;
    } else {
      return '';
    }
  };

  const checkInputValidations = (spelling) => {
    // Getting the actual word
    const actual_word = props.currentquestion.utfascii;
    // Getting only special characters in the original word
    const result = getSpecialCharacters(actual_word);

    // Process the last character typed
    const fValue = spelling.slice(-1);

    // If character not present in result
    if (!result.includes(fValue)) {
      // Concatenate only allowed characters
      return spelling.slice(0, -1) + fValue.replace(/[^A-Za-z]/g, '');
    }
    return spelling;
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
     const validatedValue = checkInputValidations(newValue);
    setInputValue(validatedValue);
    // const filteredValue = newValue.replace(/[^a-zA-Z]/g, ''); // Remove non-alphabetic characters
    // setInputValue(filteredValue);
    // if (!event.target.value.match(/^[a-zA-Z]*$/)) {
    //   setError('Only letters are allowed');
    // } else {
    //   setError('');
    // }
  };


  useEffect(()=>{
    setInputValue(props.currentText)
  },[props.currentText])

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the default form submit action

    if (!inputValue.trim()) {
      setError('Input cannot be empty');
      return;
    }
    //console.log('Submitted value:', inputValue);
    // Add your submit logic here (e.g., sending data to a server).
    props.handleGoClick(inputValue);
    setInputValue('');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.inputContainer}>
        <input
          spellCheck="false"
          onContextMenu={(e) => e.preventDefault()}
          onSelect={(e) => e.preventDefault()}
          onPaste={(e) => e.preventDefault()}
          onCopy={(e) => e.preventDefault()}
          onCut={(e) => e.preventDefault()}
          onDrag={(e) => e.preventDefault()}
          onDrop={(e) => e.preventDefault()}
          autoComplete="off"
          type="text"
          value={inputValue.toLocaleLowerCase()}
          onChange={handleChange}
          className={styles.input}
          placeholder='Enter Spelling'
        />
        <button type="submit" className={styles.button}>Go</button>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </form>
  );
}

export default InputWithButton;
