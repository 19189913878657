import React, { useState, useEffect } from 'react';
import styles from './SearchBar.module.css';
import FullWordModel from './FullWordModel';
import axios from 'axios';
import WordModel from './WordModel';
import { usePreference } from '../context/UserContext';

const SearchBar = (props) => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const appToken = sessionStorage.getItem('webtoken')
  const username = sessionStorage.getItem('username')
  const auth = "Bearer " + appToken;


  useEffect(() => {
    if (query.length === 0) {
      setResults([]);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(`https://s3napi.s3nsoftware.com/api/QuizDatas/WordSearchRegular?SearchWord=${query}`);
        const data = await response.json();
        setResults(data); // Adjust this based on the structure of your API response
      } catch (error) {
        console.error('Error fetching data: ', error);
        setResults([]);
      }
    };

    const timer = setTimeout(() => {
      fetchData();
    }, 500); // Debouncing the API request

    return () => clearTimeout(timer);
  }, [query]);

  const handleSelectection = (i) => {
    props.updateSelectedId(i);
    props.updateIsOpen(true);
    setQuery('');

 //   updateSearchedId(i)
  }
  
  return (
    <>
    <div className={styles.search_bar}>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
       <i className='bx bx-search'></i>
      {results.length > 0 && (
        <ul className={styles.search_results}>
          {results.map((result, index) => (
            <li onClick={()=>handleSelectection(result.Id)} key={index}>{result.Word} - {result.POS}</li> // Adjust according to your data
          ))}
        </ul>
      )}
    </div>
    
    </>
  );
};

export default SearchBar;