import styles from "../../common/WordModel.module.css";

import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "./login.css";

import { usePreference } from "../../context/UserContext";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">This field is required!</div>
    );
  }
};

const LoginModel = (props) => {
  const closeModal = () => {
    props.updateIsOpen(false);
  };
  const form = useRef();
  const checkBtn = useRef();

  const {
    setVisiblitily,
    setIsLoggedIn,
    updateIsLoginOpen,
    isSignUpOpen,
    updateIsSignUpOpen,
  } = usePreference();

  useEffect(() => {
    setVisiblitily(true);
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.userexisted(username).then(
        (responce) => {
          //console.log("responce : ", responce);
          if (responce === 1) {
            setLoading(false);
            //setMessage("User found!");
            localStorage.setItem("loggedin", false);
            sessionStorage.setItem("loggedin", false);

            AuthService.generateToken(username, password).then(
              (responce1) => {
                setLoading(false);
                const lgd = localStorage.getItem("loggedin");
                if (lgd == "false") {
                  setMessage("invalid credentials!");
                  return;
                }

                setLoading(false);
                setMessage("Login success!");
                setIsLoggedIn(true);
                updateIsLoginOpen(false);
                //console.log("isLogged called");

                AuthService.getUserRole(username).then((responce) => {
                  if (responce == "No User") {
                    alert("No User");
                    return;
                  }
                  //alert(responce.data);
                  window.location.reload();
                });

                //navigate("/home");
              },
              (error) => {
                const resMessage =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();

                setLoading(false);
                setMessage(resMessage);
              }
            );
          } else if (responce === 0) {
            setLoading(false);
            setMessage("Username doesn't exist.");
          } else if (responce === 2) {
            setLoading(false);
            setMessage(
              "Account not verified. Please check your email and click on the link provided to activate the account."
            );
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleSignUp = () => {
    updateIsLoginOpen(false);
    updateIsSignUpOpen(true);
  };

  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              <label
                style={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                  fontSize: "25",
                }}
              >
                Login
              </label>
              <div className="container mt-3">
                <div className="col-md-12">
                  <div className="card card-container">
                    <img
                      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      alt="profile-img"
                      className="profile-img-card"
                    />

                    <Form onSubmit={handleLogin} ref={form}>
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="username"
                          value={username}
                          onChange={onChangeUsername}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={onChangePassword}
                          validations={[required]}
                        />
                      </div>

                      <div className="form-group">
                        <button
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          <span>Login</span>
                        </button>
                      </div>

                      {/* <div><Link to={'/signup'}>Signup</Link></div> */}
                      <div>
                        <Link onClick={handleSignUp}>Signup</Link>
                      </div>

                      {message && (
                        <div className="form-group">
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                      <CheckButton style={{ display: "none" }} ref={checkBtn} />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginModel;
