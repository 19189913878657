import React, { useEffect, useState } from 'react';
import styles from './QuizzesModel.module.css'; // Define modal styles in this CSS file
import axios from 'axios';

function QuizzesModel(props) {
  const [quizzes, setQuizzes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const selectHandle = (qid) => {
    props.setQuizzId(qid);
    closeModal();
  };

  const closeModal = () => {
    props.setOpenQuizzesModel(false);
  };

  useEffect(() => {
    const appToken = sessionStorage.getItem('webtoken');
    const username = sessionStorage.getItem('username');
    const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetQuizzesBySubCatId?username=${username}&subId=${props.subid}&type=${props.type}`;
    const auth = `Bearer ${appToken}`;

    const getAllQuizzesEx = async (iteration = 1) => {
      try {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: URL,
          headers: {
            'Authorization': auth,
          },
        };

        const response = await axios.request(config);
        setQuizzes(response.data.Subcats[0].Quizzes);
        setLoading(false);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403) && iteration === 1) {
          await updateToken();
          getAllQuizzesEx(2);
        } else {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    getAllQuizzesEx();
  }, [props.subid, props.type]);

  const updateToken = async () => {
    // Implement your logic to update the token here
  };

  // Generate a light random color
  const generateLightColor = () => {
    const letters = 'CDEF'; // Using only lighter hex digits
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  // Generate a dark random color for outline and text
  const generateDarkColor = () => {
    const letters = '012345'; // Using only darker hex digits
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.modal_container}>
      {props.open && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>&times;</span>
            <div className={styles.circles_container}>
              {quizzes.map((item, index) => {
                const bgColor = generateLightColor(); // Light background color
                const outlineColor = generateDarkColor(); // Dark outline color
                const textColor = outlineColor; // Same as outline color for strong contrast

                return (
                  <div
                    title={item.QuizName}
                    className={styles.circle}
                    key={index}
                    onClick={() => selectHandle(item.Id)}
                    style={{
                      backgroundColor: bgColor,
                      color: textColor,
                      border: `3px solid ${outlineColor}`, // Apply dark outline
                    }}
                  >
                    <h6>{item.QuizNumber}</h6>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default QuizzesModel;
