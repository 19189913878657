import React from 'react';

const CarouselItem = ({ src, alt, title, description }) => (
    <div style={{
          position: 'relative',
          width: '100vw',
          height: '50vh',
          overflow: 'hidden'
        }}>
        <img src={src} alt="Full Screen Background" style={{
          width: '100%',
          height: '50vh',
         //objectFit: 'cover', // Cover the container, may be cropped
          objectPosition: 'center' // Center the image
        }}/>
      </div>
);

export default CarouselItem;
