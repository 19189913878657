import React, { useState, useEffect } from 'react';

function TextToSpeech() {
  const [text, setText] = useState('');
  const [femaleVoice, setFemaleVoice] = useState(null);

  useEffect(() => {
    // Fetch the list of available voices and set a female voice
    const voices = window.speechSynthesis.getVoices();
    const femaleVoices = voices.filter(voice => voice.gender === 'female');
    if (femaleVoices.length > 0) {
      setFemaleVoice(femaleVoices[0]);
    }
  }, []);

  const speak = () => {
    if (!text) return;

    const utterance = new SpeechSynthesisUtterance(text);
    if (femaleVoice) {
      utterance.voice = femaleVoice;
    }
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div>
      <textarea 
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Enter text to speak..."
      />
      <button onClick={speak}>Speak</button>
    </div>
  );
}

export default TextToSpeech;
