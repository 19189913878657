import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.css";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineTool,
  AiFillQuestionCircle,
  AiOutlineBook,
  AiFillFileWord,
  AiOutlineFileWord,
} from "react-icons/ai";
import { usePreference } from "../../context/UserContext";
import SearchBar from "../../common/SearchBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "boxicons/css/boxicons.min.css";
import { Link } from "react-router-dom";
import { Quiz, QuizOutlined, QuizRounded, QuizSharp, QuizTwoTone } from "@mui/icons-material";
import { QuizProvider } from "../../context/QuizContext";

const CNavbar = (props) => {
  const [nav, setNav] = useState(false);
  const {
    isVisible,
    isLogged,
    setIsLoggedIn,
    updateIsLoginOpen,
    updateIsSignUpOpen,
  } = usePreference();
  const username = localStorage.getItem("username");
  const userrole = localStorage.getItem("userrole");

  useEffect(() => {
    const loggedin = sessionStorage.getItem("loggedin");
    setIsLoggedIn(loggedin);
  }, [isLogged]);

  const handleSidebarToggle = props.handleSidebarToggle;

  return (
    <>
      {isVisible ? (
        <header className={styles.navbar}>
          <div className={styles.leftmenu} onClick={handleSidebarToggle}>
            {/* <AiOutlineMenu /> */} Spelling
          </div>
          <nav>
            <ul
              className={nav ? `${styles.menu} ${styles.active}` : styles.menu}
            >
              <li className={styles.navItem}>
                <SearchBar
                  updateSelectedId={props.updateSelectedId}
                  updateIsOpen={props.updateIsOpen}
                />
              </li>
              <li className={styles.navItem}>
                <a href="/playquiz">
                  <QuizTwoTone /> Take a quiz
                </a>
              </li>
              <li className={styles.navItem}>
                <a href="/wordpacks">
                  <AiOutlineFileWord /> Word packs
                </a>
              </li>
              {isLogged == "true" ? (
                <>
                  <li className={styles.navItem}>
                    <a href="/myquizzes">
                      <AiOutlineBook /> My Quizzes
                    </a>
                  </li>
                  {userrole == "Admin" || userrole == "SuperAdmin" ? (
                    <li className={styles.navItem}>
                      <a href="/admin">
                        <AiOutlineTool /> Admin
                      </a>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <li className={styles.navItem}>
                    <Link
                      onClick={() => {
                        updateIsLoginOpen(true);
                        updateIsSignUpOpen(false);
                      }}
                    >
                      <AiOutlineTool /> Log in
                    </Link>
                  </li>
                  <li className={styles.navItem}>
                  <Link
                      onClick={() => {
                        updateIsLoginOpen(false);
                        updateIsSignUpOpen(true);
                      }}
                    >
                       <AiOutlineBook /> Sign up
                    </Link>

                  
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
            {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
          </div>
        </header>
      ) : null}
    </>
  );
};

export default CNavbar;
