import React, { createContext, useContext, useState } from 'react';

const PreferenceContext = createContext();

export const usePreference = () => { return useContext(PreferenceContext); }

export const PreferenceProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLogged,setIsLogged]=useState(false);

  const setVisiblitily = ( value ) => {
    setIsVisible( value );
  };

  const setIsLoggedIn = ( value ) => {
    setIsLogged( value );
  };

  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignUpOpen,setIsSignUpOPen]=useState(false);

  const updateIsLoginOpen = (i) => {
    setIsLoginOpen(i);
  }
  const updateIsSignUpOpen = (i) => {
    setIsSignUpOPen(i);
  }

  const [isCommentOpen,setIsCommentOpen]=useState(false);

  const updateCommetOpen=(c)=>{
    setIsCommentOpen(c)
  }

  const [comment, setComment] = useState({});

  const updateComment=(c)=>{
    setComment(c)
  }

 
  
  
    return (
      <PreferenceContext.Provider value={ { isVisible, setVisiblitily,
                                           isLogged, setIsLoggedIn,
                                           isLoginOpen, updateIsLoginOpen,
                                           isSignUpOpen, updateIsSignUpOpen,
                                           isCommentOpen, updateCommetOpen ,
                                           comment, updateComment} }>
        { children }
      </PreferenceContext.Provider>
    );
  };
  
//   export const UserPref = () => {
//     return useContext(usePreference);
//   };
