import React from 'react'
import TextWithIcons from './TextWithIcons';
import TextToSpeech from './TextToSpeech';
import  styles  from './QA.module.css';

const QA = (props) => {
////console.log(props.currentquestion)
const showall = 
    "Part of speech: " + props.currentquestion.pos + "<br/>" +
    "Definition: " + props.currentquestion.Quizdefn + "<br/>" +
    "Etymology: " + props.currentquestion.Quizetym + "<br/>" +
    "Sentence: " + props.currentquestion.Quizsent;

    const showalla = [
    `Part of speech,  ${props.currentquestion.pos}` ,
    `,Definition,  ${props.currentquestion.defn}` ,
    `,Etymology,${props.currentquestion.etym}` ,
    `,Sentence,  ${props.currentquestion.sent}`];

  const gridItemStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.5px solid black', // Added for visual clarity
    height: '40px' // Set a height for better demonstration of centering
  };


  return (
  <div className={styles.gridContainer}>
  <div className={styles.gridItem}>{<TextWithIcons text={"Part of speech"} value={props.currentquestion.pos} audio={props.currentquestion.pos} audioenable={true} textenable={true}/>}</div>
  <div className={styles.gridItem}><TextWithIcons text={"Definition"} value={props.currentquestion.Quizdefn} audio={props.currentquestion.defn} audioenable={true} textenable={true}/></div>
  <div className={styles.gridItem}><TextWithIcons text={"Etymology"} value={props.currentquestion.Quizetym} audio={props.currentquestion.etym} audioenable={true} textenable={true}/></div>
  <div className={styles.gridItem}><TextWithIcons text={"Sentence"} value={props.currentquestion.Quizsent} audio={props.currentquestion.sent} audioenable={true} textenable={true}/></div>
  <div className={styles.gridItem}><TextWithIcons text={"Show All"} value={showall} audio={showalla} audioenable={false} textenable={true}/></div>
  <div className={styles.gridItem}><TextWithIcons text={"Play All"} value={showall} audio={showalla} audioenable={true} textenable={false}/></div>
  {/* <div className={styles.gridItem}></div> */}
</div>

   
  );
}

export default QA