import React, { useEffect, useState } from 'react'
import styles from './WordModel.module.css'
import axios from 'axios';
import AudioPlayer from '../components/quiz/AudioPlayer';
import { usePreference } from '../context/UserContext';

const FullWordModel = (props) => {
    const username = sessionStorage.getItem('username');
    const appToken = sessionStorage.getItem('webtoken')
    const auth = "Bearer " + appToken;
    const [audiolink, setAudioLink] = useState("");
    const [wik, setWik] = useState("")
    const [mwun, setMwun] = useState("")
    const [mwfree, setMwfree] = useState("")

    // const{searchedId, updateSearchedId}=usePreference();

    useEffect(() => {
        if(props.selectedid==0) return;
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://s3napi.s3nsoftware.com/api/QuizDatas/${props.selectedid}`,
            headers: {
                'Authorization': auth.toString()
            }
        };

        axios.request(config)
            .then((response) => {

                //console.log(JSON.stringify(response.data));
                setCurrentQuestion(response.data)
                let ad;

                if (response.data.audio == null) {
                    const firstChar = response.data.Word.trim() ? response.data.Word.trim().charAt(0).toLowerCase() : '';
                    ad = 'sound://gtts_audio_files/' + firstChar + '/' + response.data.Word + '.mp3';
                } else {
                    ad = response.data.audio;
                }

                const mp3 = ad.replace(/sound:\/\//g, "");
                const url = `https://s3nsoftware.com/${mp3}`;
                setAudioLink(url)

                setWik(`https://en.wiktionary.org/wiki/${response.data.Word}`)
                setMwun(`https://unabridged.merriam-webster.com/unabridged/${response.data.Word}`)
                setMwfree(`https://www.merriam-webster.com/dictionary/${response.data.Word}`)

            })
            .catch((error) => {
                //console.log(error);
            });
    }, [props.selectedid])


    const closeModal = () => {
        props.updateIsOpen(false);
    };

    const [currentquestion, setCurrentQuestion] = useState({})

    // const audioRef = useRef(null);
    // const playAudio = () => {
    //     if (audioRef.current) {
    //         audioRef.current.play().catch(e => {
    //             console.error("Error playing audio:", e);
    //             // Handle the error (e.g., display an error message)
    //         });
    //     }
    // };

    return (
        <div className={styles.modal_container}>
            {props.open && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <span className={styles.close} onClick={closeModal}>&times;</span>
                        <div>
                            <label style={{ textDecoration: 'underline', fontWeight: 'bold', fontSize: '25' }}>{currentquestion.Word}</label>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>{"Part of speech: "}</td>
                                        <td style={{ textAlign: 'start', alignContent: 'start' }}>{currentquestion.pos}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>{"Definition: "}</td>
                                        <td style={{ textAlign: 'start', alignContent: 'start' }}>{currentquestion.defn}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>{"Etymology: "}</td>
                                        <td style={{ textAlign: 'start', alignContent: 'start' }}>{currentquestion.etym}</td>
                                    </tr>

                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>{"Sentence: "}</td>
                                        <td style={{ textAlign: 'start' }} >{currentquestion.sent}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontWeight: 'bold' }}>{"Pronunciation: "}</td>
                                        <td style={{ textAlign: 'start' }} >{currentquestion.Pron} <AudioPlayer audioUrl={audiolink} currentIndex={1} size={30} color={'#000'} init={false} quiz={false} /> </td>
                                    </tr>

                                    <tr style={{ marginTop: 25 }}>
                                        <td style={{ fontWeight: 'bold' }}>{"Referencs: "}</td>
                                        <td style={{ textAlign: 'start' }} >
                                            <span style={{

                                                padding: 5,
                                                backgroundColor: '#ff6a00',
                                                borderRadius: 15

                                            }}>
                                                <a href={wik} target="_blank" rel="noopener noreferrer">Wiktionary</a>
                                            </span>
                                            <span style={{

                                                padding: 5,
                                                backgroundColor: '#ff0000',
                                                borderRadius: 15

                                            }}>
                                                <a href={mwun} target="_blank" rel="noopener noreferrer">MW Unabridged</a>
                                            </span>
                                            <span style={{

                                                padding: 5,
                                                backgroundColor: '#008000',
                                                borderRadius: 15

                                            }}>
                                                <a href={mwfree} target="_blank" rel="noopener noreferrer">MW Free</a>
                                            </span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            {/* <div style={{
          display: 'grid',
          gridGap: '1px',
        }}>
        
          <div style={{ ...gridItemStyle, gridRow: '1', gridColumn: '1' }}>{"Part of speech:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '1', gridColumn: '2' }}>{props.currentquestion.pos}</div>
          <div style={{ ...gridItemStyle, gridRow: '2', gridColumn: '1' }}>{"Definition:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '2', gridColumn: '2' }}>{props.currentquestion.defn}</div>
          <div style={{ ...gridItemStyle, gridRow: '3', gridColumn: '1' }}>{"Etymology:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '3', gridColumn: '2' }}>{props.currentquestion.etym}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '1' }}>{"Sentence:"}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '2' }}>{props.currentquestion.sent}</div>
          <div style={{ ...gridItemStyle, gridRow: '4', gridColumn: '1 / 3' }}></div>
        
        </div> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FullWordModel