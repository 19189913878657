import React from "react";
import { useEffect, useState } from "react";
import styles from "./WordModel.module.css";
import axios from "axios";
import { usePreference } from "../context/UserContext";

const CommentModel = () => {
  const username = sessionStorage.getItem("username");
  const appToken = sessionStorage.getItem("webtoken");
  const auth = "Bearer " + appToken;

  const {
    comment,
    updateComment,
    isCommentOpen,
    updateCommetOpen,
    isLoginOpen,
    updateIsLoginOpen,
    isSignUpOpen,
    updateIsSignUpOpen,
  } = usePreference();

  const closeModal = () => {
    updateCommetOpen(false);
  };

  const [localComment, setLocalComment] = useState("");
  useEffect(() => {
    setLocalComment(comment.Comment);
  }, [comment]);

  const saveUserComments = async () => {
    const userComments = {
      Username: "fixedUsername",
      CatId: 1,
      Subcat: 2,
      QuizId: 3,
      Question: 4,
      FirstInserted: new Date(),
      Comment: comment,
      WordId: 123,
    };
    comment.Comment = localComment;
    comment.FirstInserted = new Date();

    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/CustomerFeedbacks/SaveUserCommets",
        comment
      );
      if (response.status === 200) {
        //console.log("Comment saved successfully:", response.data);
        alert("Comment saved successfully!");
        updateComment({}); // Clear the input field after successful submission
      } else {
        //console.log("Failed to save comment with status:", response.status);
        alert("Failed to save comment with status: " + response.status);
      }
    } catch (error) {
      console.error("Error saving comment:", error);
      alert("Error saving comment: " + error.message);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    saveUserComments();
  };

  return (
    <div className={styles.modal_container}>
      {isCommentOpen && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <span className={styles.close} onClick={closeModal}>
              &times;
            </span>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  
                  <textarea
                    id="comment"
                    placeholder="Enter your comment here..."
                    required
                    value={localComment}
                    onChange={(e) => setLocalComment(e.target.value)}
                    rows="4"
                    cols="40"
                    style={{ marginBottom: "20px", textAlign: "center" }}
                  />
                  <button type="submit" style={{ textAlign: "center" }}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentModel;
