import React, { useEffect, useState } from 'react';
// If you're using specific icons, import them from your icon library
// For example, using Font Awesome icons:
 import { AiFillPlayCircle, AiOutlineMenu } from 'react-icons/ai';
import Swal from 'sweetalert2';
function TextWithIcons(props) 
{
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isSpeaking, setIsSpeaking] = useState(false);

    const showAlert = () => {
        Swal.fire({
          title: props.text,
          html:props.value,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          handleStop();
    });
    };

      const [femaleVoice, setFemaleVoice] = useState(null);
      useEffect(() => {
        // Fetch the list of available voices and set a female voice
        const voices = window.speechSynthesis.getVoices();
        const femaleVoices = voices.filter(voice => voice.gender === 'female');
        if (femaleVoices.length > 0) {
          setFemaleVoice(femaleVoices[0]);
        }
      }, []);
    
      const speak = () => {
        if (!props.audio) return;
        setIsSpeaking(true);
        const utterance = new SpeechSynthesisUtterance(props.audio);
        if (femaleVoice) {
          utterance.voice = femaleVoice;
        }
        utterance.onend = () => {
          setIsSpeaking(false);
          setCurrentTextIndex((prevIndex) => (prevIndex + 1) % props.audio.length);
          
      };

        window.speechSynthesis.speak(utterance);
      };
     const handlePlay = () => {
        if (!isSpeaking) {
            speak(props.audio[currentTextIndex]);
        }
        showAlert();
    };

    const handleStop = () => {
        speechSynthesis.cancel();
        setIsSpeaking(false);
    };
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <div style={{ flexGrow: 1 }}>{props.text}</div>
      {props.textenable?<div style={{ width: '24px', height: '24px' }}> {props.textenable?<AiOutlineMenu onClick={showAlert}  />:<></>} </div>:<></>}
      {props.audioenable?<div style={{ width: '24px', height: '24px' }}> {props.audioenable?<AiFillPlayCircle onClick={isSpeaking?handleStop:handlePlay}  />:<></>} </div>:<></>}
    </div>
  );
}

export default TextWithIcons;