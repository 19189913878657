import React, { useState } from 'react';
import axios from 'axios';

const UploadUserPhoto = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [username, setUsername] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedFile || !username) {
      alert("Please provide both a username and a photo.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("username", username);

    axios
      .post(
        "https://s3napi.s3nsoftware.com/api/UserProfiles/UploadImageFile",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setUploadStatus("Image uploaded successfully!");
          alert(response.data);
        } else {
          setUploadStatus("Upload failed!");
        }
      })
      .catch((error) => {
        console.error("Upload error:", error);
        setUploadStatus("Upload error!");
      });
  };

  return (
    <div>
      <h2>Upload User Photo</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input type="text" value={username} onChange={handleUsernameChange} required />
        </div>
        <div>
          <label>Photo:</label>
          <input type="file" accept="image/*" onChange={handleFileChange} />
        </div>
        <button type="submit">Upload</button>
      </form>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default UploadUserPhoto;
