import { format } from 'date-fns';
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Swal from 'sweetalert2';
import { styled } from '@mui/material';

import styles from './Home.module.css'
import { usePreference } from '../../context/UserContext'



const Card = ({ Id, Amount, StartDate, EndDate, StartTime, EndTime, EventName, SessionType, SessionId,Description,username }) => {

    const [loggedIn, setLoggedIn] = useState('');
    const { setVisiblitily, setIsLoggedIn,isLoginOpen, updateIsLoginOpen } = usePreference()


    // const createOrder = useCallback((data, actions) => {
    //     return actions.order.create({
    //         purchase_units: [{
    //             amount: {
    //                 currency_code: 'USD',
    //                 value: Amount.toFixed(2)
    //             },
    //             custom_id: Id.toString(),
    //             description: EventName,
    //         }],
    //         application_context: {
    //             brand_name: "Spelling!!!",
    //             shipping_preference: "NO_SHIPPING"
    //         }
    //     });
    // }, [Amount, Id, EventName]);
    const createOrder = useCallback((data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    currency_code: 'USD',
                    value: Amount.toFixed(2),
                    breakdown: {
                        item_total: {
                            currency_code: 'USD',
                            value: Amount.toFixed(2)
                        }
                    }
                },
                custom_id: Id.toString(),
                description: EventName,
                items: [{
                    name: EventName,
                    unit_amount: {
                        currency_code: 'USD',
                        value: Amount.toFixed(2)
                    },
                    quantity: 1,
                    description: `${SessionType} ${StartDate} ${StartTime} - ${EndDate} ${EndTime}`
                }]
            }],
            application_context: {
                brand_name: "Spelling!!!",
                shipping_preference: "NO_SHIPPING"
            }
        });
    }, [Amount, Id, EventName,StartDate,EndDate,StartTime,EndTime,SessionType]);
    

    const onPaymentSuccess = useCallback((data, actions) => {
        return actions.order.capture().then(details => {
            const paymentInfo = {
                payerID: data.payerID,
                orderID: data.orderID,
                paymentID: details.id,
                paymentToken: details.purchase_units[0].payments.captures[0].id,
                amount: details.purchase_units[0].amount.value,
                currency: details.purchase_units[0].amount.currency_code,
                description: details.purchase_units[0].description,
                phoneNumber: details.payer.phone.phone_number.national_number,
                email: details.payer.email_address,
                firstname: details.payer.name.given_name,
                lastname: details.payer.name.surname,
                IdD:details.purchase_units[0].custom_id
            };

            handleeSubmit(paymentInfo);
        }).catch(error => {
            console.error('Error capturing order:', error);
        });
    }, []);

    useEffect(() => {
        const getSessionStorageItem = () => {
            setLoggedIn(sessionStorage.getItem('loggedin') || 'false');
        };
        getSessionStorageItem();
    }, []);

    const handleeSubmit = async (paymentInfo) => {
        // Implement your submission logic her
    
        const userMaster = {
            UserName: username,
            Verified: true,
            Verifiedon: new Date().toISOString(),
            IsNSF: false
          };
      
          ////console.log("usermaster ", JSON.stringify(userMaster))
      
          //let iid= localStorage.setItem('eId',id);
      
          const signupModel = {
            IsCheckSMS: true,
            UserMaster: userMaster,
            PaymentInfo: paymentInfo,
            EventId: paymentInfo.IdD,
          };
      
      
        //  //console.log("sign ", JSON.stringify(signupModel))
      
          const response = await fetch('https://s3napi.s3nsoftware.com/api/UserMasters/SaveEExisteUserInformation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(signupModel)
          });
      
          if (response.ok) {
            Swal.fire({
              title: 'Registration completed!',
              icon: 'success',
              confirmButtonText: 'Ok'
            });
            
          } else {
            Swal.fire({
              title: 'Registration failed!',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
          }

    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMMM dd');
    };

    const formatTime = (timeString) => {
        return format(new Date(timeString), 'HH:mm');
    };

    const handleEnrol=()=>{
        // Swal.fire({
        //     title: 'Login First',
        //     icon: 'info',
        //     html: 'Students will require an account to access class quizzes. Please <a href="/login" style="color: #3085d6;">sign in</a> or <a href="/signup" style="color: #3085d6;">sign up</a> now before making the payment.',
        //     confirmButtonText: 'Ok'
        //   });
        updateIsLoginOpen(true);
    }
    
    return (
        <div className={styles.w25}>
            <div className="card h-100">
                <div className="card-body d-flex flex-column justify-content-between p-4">
                    <div>
                        <h6 className="text-uppercase text-muted">{EventName}</h6>
                        <h4 className="display-6 fw-bold">${Amount}</h4>
                        <hr />
                        <ul className="list-unstyled">
                            <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                        <path d="M13.485 1.85a.7.7 0 0 1 .936.936L7.187 9.94 3.7 6.453a.7.7 0 1 1 .99-.99l2.49 2.49L13.485 1.85Z" />
                                    </svg>
                                </span>
                                {formatDate(StartDate)} - {formatDate(EndDate)}
                            </li>
                            <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                        <path d="M13.485 1.85a.7.7 0 0 1 .936.936L7.187 9.94 3.7 6.453a.7.7 0 1 1 .99-.99l2.49 2.49L13.485 1.85Z" />
                                    </svg>
                                </span>
                                {formatTime(StartTime)} - {formatTime(EndTime)} Central
                            </li>
                          
                            <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                        <path d="M13.485 1.85a.7.7 0 0 1 .936.936L7.187 9.94 3.7 6.453a.7.7 0 1 1 .99-.99l2.49 2.49L13.485 1.85Z" />
                                    </svg>
                                </span>
                                {SessionType}
                            </li>
                            <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                        <path d="M13.485 1.85a.7.7 0 0 1 .936.936L7.187 9.94 3.7 6.453a.7.7 0 1 1 .99-.99l2.49 2.49L13.485 1.85Z" />
                                    </svg>
                                </span>
                                {Description}
                            </li>
                            <li className="d-flex mb-2">
                                <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-lg">
                                        <path d="M13.485 1.85a.7.7 0 0 1 .936.936L7.187 9.94 3.7 6.453a.7.7 0 1 1 .99-.99l2.49 2.49L13.485 1.85Z" />
                                    </svg>
                                </span>
                                {SessionId===1?"Mondays/Wednesdays":"Tuesdays/Thursdays"}
                            </li>
                        </ul>
                    </div>
                    {loggedIn === 'true' ? (
                        <PayPalScriptProvider options={{
                            "client-id": "AbjTGxNRgw-BNbD2ILmTePbIWjsxHJs951dfZAOh73eJNK-BwkCpbNN_0AllDm7Fjo4GE_cqwjivh0st",
                            "components": "buttons",
                            "currency": "USD"
                        }}>
                            <PayPalButtons
                                createOrder={createOrder}
                                onApprove={onPaymentSuccess}
                            />
                        </PayPalScriptProvider>
                    ) : (
                        <Link onClick={handleEnrol} className="btn btn-primary d-block w-100">Enroll Now</Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Card;
