import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const WordPacks = () => {
  const [wordPacks, setWordPacks] = useState([]);
  const [selectedPack, setSelectedPack] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the provided API
    axios
      .get(
        "https://s3napi.s3nsoftware.com/api/QuizDatas/WordPacksInfo?Username=YuvarajThunder"
      )
      .then((response) => {
        setWordPacks(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setLoading(false);
      });
  }, []);

  const createOrder = (price, id, packageName) => (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price.toString(),
          },
          description: `${packageName} - ${id}`,
        },
      ],
    });
  };

  const handlePaymentSuccess = (details, data) => {
    alert("Payment completed successfully!");
    // Handle the payment success logic here
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row">
      {wordPacks.map((pack) => (
        <div className="col-lg-3 col-md-6" key={pack.Id}>
          <div className="card text-left card-shadow on-hover border-0 mb-4">
            <div className="card-body font-14">
              <h5 className="mt-3 mb-1 font-weight-medium">{pack.Package}</h5>
              <h6 className="subtitle font-weight-normal">
                {pack.Discription || "No description available"}
              </h6>
              <div className="pricing my-3">
                <sup>$</sup>
                <span className="monthly display-5">{pack.Price}</span>
                <small className="monthly">/mo</small>
                <span className="d-block">{pack.DiscountDesc}</span>
              </div>

              <ul className="list-inline text-left">
                {pack.Categories.map((item, index) => (
                  <li
                    key={index}
                    className={`d-block py-2 ${
                      item.IsLocked ? "font-weight-bold" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={!item.IsLocked}
                      disabled={!item.IsLocked}
                      readOnly
                    ></input>{" "}
                    {item.CategoryName}
                  </li>
                ))}
              </ul>
              <div className="bottom-btn">
                {pack.IsSubscribed ? (
                  <button
                    className="btn btn-secondary btn-md text-white btn-block"
                    disabled
                  >
                    Subscribed
                  </button>
                ) : (
                  <div>
                    <button
                      className="btn btn-success-gradiant btn-md text-white btn-block mb-5"
                      onClick={() => setSelectedPack(pack.Id)}
                    >
                      <span>Choose Plan</span>
                    </button>
                    {selectedPack === pack.Id && (
                      <PayPalScriptProvider
                        options={{
                          "client-id":
                            "AbjTGxNRgw-BNbD2ILmTePbIWjsxHJs951dfZAOh73eJNK-BwkCpbNN_0AllDm7Fjo4GE_cqwjivh0st",
                          components: "buttons",
                          currency: "USD",
                        }}
                      >
                        <PayPalButtons
                          createOrder={createOrder(
                            pack.Price,
                            pack.Id,
                            pack.Package
                          )}
                          onApprove={(data, actions) =>
                            actions.order
                              .capture()
                              .then((details) =>
                                handlePaymentSuccess(details, data)
                              )
                          }
                        />
                      </PayPalScriptProvider>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WordPacks;
