import axios from "axios";
import CryptoJS from 'crypto-js';

const API_URL = "https://s3napi.s3nsoftware.com/";


const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const userexisted = async (username) => {
  const response = await axios.get(API_URL + "api/UserMasters/UserIsExisted?UserName=" + username);
  //console.log(" userexisted : " + response.data);
  return response.data;
}


const generateToken = async (username, password) => {
  try {

    const parameters = new URLSearchParams();
    parameters.append('username', username);
    parameters.append('password', password);
    parameters.append('grant_type', 'password');

    await axios.post(API_URL + 'token', parameters)
      .then(response => {
        // Handle response
        if (response.status == 200) {
          //console.log("access token :: ", response.data);

          localStorage.setItem('webtoken', response.data.access_token);
          sessionStorage.setItem('webtoken', response.data.access_token);

          localStorage.setItem('tokenexpiry', response.data.expires_in);
          sessionStorage.setItem('tokenexpiry', response.data.expires_in);

          localStorage.setItem('loggedin', true);
          sessionStorage.setItem('loggedin', true);

          localStorage.setItem('username', username);
          sessionStorage.setItem('username', username);


          return true;
        } else {
          console.error("Failed to generate token. Status:", response.status);
          return false;
        }
      })
      .catch(error => {
        // Handle error
        //console.log("error");
        //console.log(error);
        return false;
      });



    // const url = "https://s3napi.s3nsoftware.com/token";
    // const parameters = new URLSearchParams();
    // parameters.append('username', username);
    // parameters.append('password', password);
    // parameters.append('grant_type', 'password');

    // const response = await axios.post(url, parameters);

    // if (response.status === 200) {
    //     // Assuming the response data is directly the token view
    //     const tokenView = response.data;
    //     //console.log("Token:", tokenView.access_token);
    //     return tokenView;
    // } else {
    //     // Handle other HTTP statuses
    //     console.error("Failed to generate token. Status:", response.status);
    //     return null;
    // }
  } catch (error) {
    // Handle any errors in the request
    console.error("Error generating token:", error);
    throw error;
  }
};

const login = async (username, password) => {
  const response = await axios
    .post(API_URL + "signin", {
      username,
      password,
    });
  if (response.data.username) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const logout = async () => {
  localStorage.removeItem("user");
  const response = await axios.post(API_URL + "signout");
  return response.data;
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const registerUser = async (userMaster) => {
  try {
    const response = await axios.post(`${API_URL}api/UserMasters/SaveUserInformation`, userMaster);
    return response.data;
  } catch (error) {
    // Handle or throw the error as needed
    console.error("Registration error:", error);
    throw error;
  }
};

const getUserRole = async (username) => {
  const response = await axios.get(API_URL + "api/UserProfiles/GetRoles?username=" + username);
  //console.log(" userrole : " + response.data);
  localStorage.setItem("userrole", response.data);
  return response.data;
}


const AuthService = {
  registerUser,
  register,
  login,
  logout,
  getCurrentUser,
  userexisted,
  generateToken,
  getUserRole

}



export default AuthService;
