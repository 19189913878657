import React, { useState } from "react";
import "./Sidebar.css";
import "boxicons/css/boxicons.min.css";
import Swal from "sweetalert2";

const SideNavbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleMouseEnter = () => {
    setSidebarOpen(true);
  };

  const username = sessionStorage.getItem("username");
  const isLogged = sessionStorage.getItem("loggedin");

  const handleMouseLeave = () => {
    setSidebarOpen(false);
  };

  const handleArrowClick = (e) => {
    e.target.closest("li").classList.toggle("showMenu");
  };

  const toggleSidebar = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickSignOut = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once signed out, you will need to log in again.",
      icon: "warning",
      showCancelButton: true, // Show the cancel button
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, sign me out!',
      cancelButtonText: 'Cancel',
      dangerMode: true,
      allowOutsideClick: false, // Prevents closing the modal by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, perform sign-out
        Swal.fire({
          title: "Signed Out!",
          text: "You have been signed out successfully.",
          icon: "success",
        });
  
        // Sign out logic
        localStorage.setItem("loggedin", false);
        sessionStorage.setItem("loggedin", false);
        // Reload or redirect to home page
        window.location.href = "/";
      } else {
        // User canceled, do nothing
        Swal.fire({
          title: "You are still signed in!",
          icon: "info",
          timer: 2000, // Automatically close after 3 seconds
          showConfirmButton: false, // Hide the confirm button
        });      }
    });
  };
  

  return (
    <>
      <div
        className={`sidebar ${sidebarOpen ? "" : "close"} ${
          mobileOpen ? "open" : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="logo-details">
          <i className="bx bxl-c-plus-plus"></i>
          <span className="logo_name">Spelling !!!</span>
        </div>
        <ul className="nav-links">
          <li>
            <a href="#">
              <i className="bx bx-grid-alt"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Category
                </a>
              </li>
            </ul>
          </li>
          <li>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Category</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={handleArrowClick}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Category
                </a>
              </li>
              <li>
                <a href="#">HTML & CSS</a>
              </li>
              <li>
                <a href="#">JavaScript</a>
              </li>
              <li>
                <a href="#">PHP & MySQL</a>
              </li>
            </ul>
          </li>
          <li>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-book-alt"></i>
                <span className="link_name">Posts</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={handleArrowClick}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Posts
                </a>
              </li>
              <li>
                <a href="#">Web Design</a>
              </li>
              <li>
                <a href="#">Login Form</a>
              </li>
              <li>
                <a href="#">Card Design</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-pie-chart-alt-2"></i>
              <span className="link_name">Analytics</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Analytics
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-line-chart"></i>
              <span className="link_name">Chart</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Chart
                </a>
              </li>
            </ul>
          </li>
          <li>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-plug"></i>
                <span className="link_name">Plugins</span>
              </a>
              <i
                className="bx bxs-chevron-down arrow"
                onClick={handleArrowClick}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Plugins
                </a>
              </li>
              <li>
                <a href="#">UI Face</a>
              </li>
              <li>
                <a href="#">Pigments</a>
              </li>
              <li>
                <a href="#">Box Icons</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-compass"></i>
              <span className="link_name">Explore</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Explore
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#">
              <i className="bx bx-history"></i>
              <span className="link_name">History</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  History
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="/settings">
              <i className="bx bx-cog"></i>
              <span className="link_name">Setting</span>
            </a>
            <ul className="sub-menu blank">
              <li>
                <a className="link_name" href="#">
                  Setting
                </a>
              </li>
            </ul>
          </li>
          {isLogged == "true" ? (
            <>
              <li>
                <div className="profile-details">
                  <div className="profile-content">
                    {
                      <img
                        src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                        alt="profileImg"
                      />
                    }
                    {/* <img alt="profileImg" /> */}
                  </div>
                  <div className="name-job">
                    <div
                      className="profile_name"
                      style={{ textTransform: "uppercase", cursor: "pointer" }} // Convert text to uppercase and make cursor pointer
                      title={username || "Unknown"} // Show full username on mouse hover or 'Unknown' if username is null
                    >
                      {username
                        ? username.length > 10
                          ? `${username.substring(0, 10)}...`
                          : username
                        : "No username"}
                    </div>
                    <div className="job"></div>
                  </div>
                  <i className="bx bx-log-out" onClick={handleClickSignOut}></i>
                </div>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
      {/* <div className="toggle-button" onClick={toggleSidebar}>
        <i className={`bx ${mobileOpen ? 'bx-x' : 'bx-menu'}`}></i>
      </div> */}
    </>
  );
};

export default SideNavbar;
