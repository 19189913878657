import React, { useEffect, useState } from 'react'
import { usePreference } from '../../context/UserContext'
import WorkingPage from '../WorkingPage'
import Recorder from '../../common/Recorder'
import styles from './Home.module.css'
import Gallery from './Gallery'
import { Link } from 'react-router-dom'
import { LightMode } from '@mui/icons-material'
import { lightBlue } from '@mui/material/colors'

import axios from 'axios'
import EventCard from './EventCard'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import Carousel from './Carousel'
import Card from './Card'

import image1 from './../../images/image1.png'
import image2 from './../../images/image2.webp'
import niharimage from '../../images/nihar.jpg'
import Paypal from '../paypal/Paypal'
import EncryptionDecryption, { Decrypt } from '../../common/EncryptionDecryption'




const Home = () => {

    const { setVisiblitily, setIsLoggedIn, isLoginOpen, updateIsLoginOpen } = usePreference()
    const [cards, setCards] = useState([]);
    const [username, setUsername] = useState('');

    useEffect(() => {
        const token = sessionStorage.getItem('webtoken');
        const expiryIn = sessionStorage.getItem('tokenexpiry');
        //console.log(token);
        //console.log(expiryIn);
        const loggedin = sessionStorage.getItem('loggedin');

        const un = sessionStorage.getItem('username');
        setUsername(un);

        setIsLoggedIn(loggedin);
        setVisiblitily(true);
    }, [])

    useEffect(() => {

        const fetchData = async () => {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://s3napi.s3nsoftware.com/api/QuizDatas/GetEvents',
                headers: {}
            };

            axios.request(config)
                .then((response) => {
                    //console.log(JSON.stringify(response.data));
                    setCards(response.data);
                })
                .catch((error) => {
                    //console.log(error);
                });
        };

        fetchData();

    }, [])
    const handleSelect = (data) => {
        // Redirect and pass data using state
        //import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
        //const navigate = useNavigate(); // useNavigate instead of useHistory
        //navigate('/details', { state: { data } }); // Use navigate with state
    };



    const carouselItems = [
        { src: niharimage, alt: 'No photo', title: 'Spelling !!! Summer Camp', description: 'Nihar Janga, 2006 Scripps Spelling Bee Champion' },
       // { src: image2, alt: 'Second slide', title: 'Spelling!!! Summer Camp', description: 'Nihar Janga, 2006 Scripps Spelling Bee Champion' },
    ];

    const cardData = [
        { title: 'Standard', price: '15', features: ['Lectus ut nibh quam, felis porttitor.', 'Ante nec venenatis etiam lacinia.', 'Porta suscipit netus ad ac.'], mostPopular: false },
        { title: 'Pro', price: '38', features: ['Lectus ut nibh quam, felis porttitor.', 'Ante nec venenatis etiam lacinia.', 'Porta suscipit netus ad ac.', 'Morbi praesent aptent integer at.', 'Nisl potenti ut auctor lobortis.'], mostPopular: true },
        { title: 'Enterprise', price: '70', features: ['Lectus ut nibh quam, felis porttitor.', 'Ante nec venenatis etiam lacinia.', 'Porta suscipit netus ad ac.', 'Morbi praesent aptent integer at.', 'Nisl potenti ut auctor lobortis.', 'Ornare accumsan curae duis eget.'], mostPopular: false }
    ];

    return (
        <>
{/* {EncryptionDecryption.Encrypt("Suresh")}
{Decrypt("pgXbNsE8dHfr/6yG+xIgPw==")} */}

             {/* <div style={{
          position: 'relative',
          width: '100vw',
          height: '50vh',
          overflow: 'hidden'
        }}>
        <img src={niharimage} alt="Full Screen Background" style={{
          width: '100%',
          height: '50vh',
         //objectFit: 'cover', // Cover the container, may be cropped
          objectPosition: 'center' // Center the image
        }}/>
      </div> */}

      <Carousel items={carouselItems}/>

            <div className="container py-4 py-xl-5">
                <div className="row mb-5">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2 >Spelling!!! Summer Camp</h2>
                        <h5 style={{ fontFamily: 'bold' }} className="col-md-8 col-xl-6 text-center mx-auto">Nihar Janga, 2016 Scripps National Spelling Bee Champion</h5>
                    </div>
                </div>
                <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                    {cards.map((card, index) => (
                        <Card key={index} {...card} username={username} />
                    ))}
                </div>
            </div>

            {/* <div className={styles.eventcontainer}>
            {cards.map((event, index) => (
                <EventCard
                    key={index}
                    id={event.Id}
                    name={event.EventName}
                    startDate={event.StartDate}
                    endDate={event.EndDate}
                    startTime={event.StartTime}
                    endTime={event.EndTime}
                    price={event.Amount}
                />
            ))}
        </div> */}
            <div style={{ marginBottom: "50px" }}></div>

        </>


        // <div style={{ backgroundColor: 'gray' }} >
        //     {cards.map((card, index) => (
        //          <EventCard key={index} data={card} onSelect={handleSelect} /> 
        //     ))}
        //     {/* <Gallery />
        //     <div style={{ width: '100%', textAlign: 'center', marginBottom: '100px' }}>
        //         <Link to='/events'>
        //             <button className='btn btn-primary' style={{ textAlign: 'center' }} >Register</button></Link>
        //     </div> */}

        // </div>

    );

}

export default Home